export const lanLinks = {
  en: {
    locale: 'en',
    title: 'en',
    urlBase: '/en',
  },
  zh: {
    locale: 'zh',
    title: '中文',
    urlBase: '',
  },
}
